import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiError } from '../../../../../../api/src/_core/interfaces/api-error.interface';
import { SessionService } from '../../session/session.service';
import { WppOpenIntegrationService } from './wpp-open-integration.service';
import { GlobalService } from '../../global/global.service';
import { PublicOrganization } from '../../../../../../api/src/organization/organization.entity';
import { environment } from '../../../../environments/environment';
import { GlobalQuery } from '../../global/global.query';
import { WppOpenLoginResponse } from '../../session/session.model';

export interface ApiErrorSource extends ApiError {
	source?: 'wppOpenSignIn' | 'getOrganizationFromHub' | 'getOrganizationSettings' | 'organizationLookup';
}

@Injectable({
	providedIn: 'root',
})
export class WppOpenMiddlewareService {
	constructor(
		private readonly sessionService: SessionService,
		private readonly wppOpenIntegrationService: WppOpenIntegrationService,
		private readonly globalService: GlobalService,
		private readonly globalQuery: GlobalQuery
	) {}

	authAndSetGlobalOrganizationConfig(email: string, accessToken: string): Observable<WppOpenLoginResponse> {
		return this.wppOpenIntegrationService.getOrganizationFromHub(email, accessToken).pipe(
			switchMap((organization) => {
				this.globalService.triggerSaveMessage('Organization successfully retrieved');
				// Check organization config and stop the chain if it fails
				if (!this.setGlobalOrganizationConfig(organization)) {
					return throwError(() => ({
						message: 'Missing configuration settings for this organization.',
						source: 'organizationLookup',
					}));
				}
				// Only one call to wppOpenSignIn is needed
				return this.sessionService.wppOpenSignIn(email, accessToken).pipe(
					catchError((err) =>
						throwError(() => ({
							...err.error,
							source: 'wppOpenSignIn',
						}))
					)
				);
			}),
			switchMap((response) => {
				this.globalService.triggerSaveMessage('User successfully authenticated');
				// Optionally perform the additional HTTP call if settings are missing
				if (!this.globalQuery.getValue().settings) {
					return this.globalService.get().pipe(
						catchError((err) =>
							throwError(() => ({
								...err.error,
								source: 'getOrganizationSettings',
							}))
						),
						map(() => {
							this.sessionService.setLoading(false);
							return response;
						})
					);
				}
				this.sessionService.setLoading(false);
				return of(response);
			}),
			catchError((err) =>
				err.source
					? throwError(() => err)
					: throwError(() => ({
							...err.error,
							source: 'organizationLookup',
					  }))
			)
		);
	}

	/**
	 * Set global config from Organization config into global environment.
	 *
	 * @param organization
	 * @private
	 */
	private setGlobalOrganizationConfig(organization: Partial<PublicOrganization>): boolean {
		const globalApiSettings = environment.apiSettings;
		if (globalApiSettings.length === 0) {
			console.error('Missing global API settings.');
			return false;
		}

		const foundOrganizationDefinitions = globalApiSettings.find((val) => {
			return val.remoteId === organization.remoteId;
		});
		if (!foundOrganizationDefinitions) {
			console.error(`Missing organization definitions for: ${organization.remoteId}`);
			return false;
		}
		this.globalService.setGlobalOrganizationEnvironmentConfig(foundOrganizationDefinitions);
		return true;
	}
}
