import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalQuery } from '../../global/global.query';
import { Tactic } from '../tactic/tactic.model';
import { Cost } from './cost.model';
import { CostStore } from './cost.store';

/**
 * Cost Service
 * This service handles the cost item logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class CostService {
	constructor(private costStore: CostStore, private http: HttpClient, private globalQuery: GlobalQuery) {}

	// get() {}

	/**
	 * Set the cost items in the Akita store
	 */
	set(costs: Cost[]) {
		this.costStore.set(costs.map((cost) => this.prepareForAkita(cost)));
	}

	/**
	 * Create a new cost on the API
	 */
	create(tacticId: Tactic['id'], cost: Cost) {
		this.costStore.setLoading(true);

		return this.http
			.post<Cost>(
				`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/cost`,
				this.prepareForApi(cost)
			)
			.pipe(
				tap((newValue) => {
					const exists = this.costStore.getValue().entities[cost.id];
					if (exists) {
						this.costStore.update(cost.id, this.prepareForAkita(newValue));
					} else {
						this.add(this.prepareForAkita(newValue));
					}
					this.costStore.setLoading(false);
				})
			);
	}

	/**
	 * Add a cost to the Akita store.
	 */
	add(cost: Cost) {
		this.costStore.add(cost);
	}

	/**
	 * Update a cost's field on the API.
	 * If the cost doesn't have a `created` field, it will only be updated in the Akita store. This is for new costs before they are saved to the API.
	 */
	update(tacticId: Tactic['id'], id: Cost['id'], cost: Partial<Cost>) {
		this.costStore.setLoading(true);

		if (cost.created) {
			return this.http
				.put<Cost>(
					`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/cost/${id}`,
					this.prepareForApi(cost)
				)
				.pipe(
					tap((newValue) => {
						// HACK: since the API response does not returns when brandAllocations is empty []
						// we look at the original cost item and if it had brandAllocations, but the newValue doesn’t, we add it back in
						if (cost?.brandAllocations?.length === 0) {
							newValue.brandAllocations = [];
						}

						this.costStore.update(cost.id, this.prepareForAkita(newValue));
						this.costStore.setLoading(false);
					}),
					catchError((err) => {
						this.costStore.update(cost.id, this.costStore.getValue().entities[cost.id]);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita', cost);
			this.costStore.update(cost.id, this.prepareForAkita(cost));
			return of(cost);
		}
	}

	/**
	 * Remove a cost from the API
	 */
	remove(tacticId: Tactic['id'], id: Cost['id']) {
		this.costStore.setLoading(true);

		return this.http
			.delete<Cost>(`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/cost/${id}`)
			.pipe(
				tap((newValue) => {
					this.costStore.remove(id);
					this.costStore.setLoading(false);
				})
			);
	}

	/**
	 * Normalize a cost for the Akita store.
	 */
	prepareForAkita(cost: Partial<Cost>): Cost {
		const obj: Partial<Cost> = {};
		const settings = this.globalQuery.getValue().settings;

		//console.log('Preparing for Akita', cost);

		if (cost) {
			Object.keys(cost).forEach((key) => {
				switch (key) {
					case 'brandAllocations':
						// Reconcile with brand objects if they aren't already fixed
						if (cost[key]?.length && !cost[key][0].name) {
							obj[key] = cost[key]?.map((allocation) => {
								return {
									...allocation,
									...settings.brands.find((b) => b.id === allocation['brand'].id),
								};
							});
						} else {
							obj[key] = cost[key];
						}
						break;

					default:
						obj[key] = cost[key];
						break;
				}
			});
		}

		return obj as Cost;
	}

	/**
	 * Normalize a cost for the API.
	 */
	prepareForApi(cost: Partial<Cost>) {
		const obj = {};

		//console.log('Preparing for API', cost);

		if (cost) {
			Object.keys(cost).forEach((key) => {
				switch (key) {
					case 'amountPlanned':
						obj['value'] = cost[key]?.toString();
						break;

					case 'costType':
						obj['costTypeId'] = cost[key].id;
						break;

					case 'id':
					case 'created':
					case 'author':
					case 'tacticId':
						break;

					case 'brandAllocations':
						{
							obj['brandAllocations'] = cost[key]?.map((brand: any) => ({
								brandId: brand.id || brand.brandId,
								// Split brand allocation evenly among brands
								split: brand.split?.toString() || String(1 / cost[key].length),
								distributions: (brand.budgetDistributions || brand.distributions)?.map((distribution) => ({
									...distribution,
									id: undefined,
									brandAllocationId: undefined,
									split: distribution.split?.toString(),
								})),
							}));
						}
						break;

					default:
						obj[key] = cost[key];
						break;
				}
			});
		}

		return obj;
	}

	/**
	 * Return an object of total cost amounts by brand.
	 */
	getBrandTotals(costs: Cost[], fieldSuffix: string = 'Planned') {
		const obj = {};

		costs?.forEach((cost) => {
			// Get the brands and then split the amount
			cost.brandAllocations?.forEach((brand) => {
				// Find the name depending on if we're using the flattend version or not
				const name = brand?.name || brand?.['brand']?.name;

				// Create an object
				if (!obj[name]) {
					obj[name] = 0;
				}

				const split = brand.split || 1 / cost.brandAllocations.length;
				const value = (cost[`amount${fieldSuffix}`] || 0) * split;
				// Increment the value of our amount / # of brands
				// obj[name] += cost[`amount${fieldSuffix}`] / cost.brandAllocations.length;
				obj[name] += Number(value.toFixed(2));
			});
		});

		return obj;
	}
}
