import { Column, Entity, Index, JoinTable, ManyToMany, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { PublicRetailer, Retailer } from '../retailer/retailer.entity';
import { ProgramPhase, PublicProgramPhase } from '../program-phase/program-phase.entity';
import { ProgramSector, PublicProgramSector } from '../program-sector/program-sector.entity';
import { ProgramType, PublicProgramType } from '../program-type/program-type.entity';
import { PublicTacticPhase, TacticPhase } from '../tactic-phase/tactic-phase.entity';
import { CostType, PublicCostType } from '../cost-type/cost-type.entity';
import { BudgetPeriod, PublicBudgetPeriod } from '../budget-period/budget-period.entity';
import { MeasurementType, PublicMeasurementType } from '../measurement-type/measurement-type.entity';
import { ExternalIdType, PublicExternalIdType } from '../external-id-type/external-id-type.entity';
import { InvestmentType, PublicInvestmentType } from '../investment-type/investment-type.entity';
import { Brand } from '../brand/brand.entity';
import { Category } from '../category/category.entity';
import { FundingSource } from '../funding-source/funding-source.entity';
import { BrandInitiative } from '../brand-initiative/brand-initiative.entity';
import { UpdateOrgSettingsDto } from './dtos/update-org-settings.dto';
import { AuthenticationStrategy } from '../authentication-strategy/authentication-strategy.entity';
import { VendorType } from '../vendor-type/vendor-type.entity';
import { MeasurementGroup, PublicMeasurementGroup } from '../measurement-group/measurement-group.entity';
import { Vendor } from '../vendor/vendor.entity';
import { Agency, PublicAgency } from '../agency/agency.entity';
import { ProgramUtilization, PublicProgramUtilization } from '../program-utilization/program-utilization.entity';
import { PublicTacticAgency, TacticAgency } from '../tactic-agency/tactic-agency.entity';

export interface LogoAsset {
	url: string;
}

export enum SignupMethod {
	Allow = 'allow',
	InviteOnly = 'inviteOnly',
}

export enum AuthenticationStrategyLegacy {
	Basic = 'basic',
	GeometryOktaSSO = 'geometryOktaSso',
	KimberlyClarkeOktaSSO = 'kimberlyClarkeOktaSso',
}

export type PublicOrganization = Pick<
	Organization,
	'id' | 'name' | 'slug' | 'logo' | 'signupMethod' | 'settings' | 'created' | 'remoteId'
> & {
	budgetPeriods?: PublicBudgetPeriod[];
	retailers: PublicRetailer[];
	agencies?: PublicAgency[];
	programPhases: PublicProgramPhase[];
	programSectors: PublicProgramSector[];
	programTypes: PublicProgramType[];
	programUtilizations: PublicProgramUtilization[];
	tacticPhases: PublicTacticPhase[];
	costTypes: PublicCostType[];
	measurementGroups: PublicMeasurementGroup[];
	measurementTypes: PublicMeasurementType[];
	externalIdTypes: PublicExternalIdType[];
	investmentTypes?: PublicInvestmentType[];
	tacticAgencies?: PublicTacticAgency[];
};

@Entity('organizations')
@Index(['remoteId'], { unique: true })
export class Organization {
	constructor(value?: Partial<Organization>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', {
		nullable: false,
		default: '[Placeholder]',
	})
	name: string;

	@Column('text', {
		nullable: false,
		default: 'placeholder-slug',
	})
	slug: string;

	@Column('boolean', {
		nullable: false,
		default: false,
	})
	enabled: boolean;

	@Column('jsonb', { nullable: true })
	logo?: LogoAsset[];

	@Column({
		type: 'enum',
		enum: SignupMethod,
		default: SignupMethod.InviteOnly,
	})
	signupMethod: SignupMethod;

	@OneToMany(() => AuthenticationStrategy, (authStrategy) => authStrategy.organization, {
		nullable: true,
	})
	authenticationStrategies?: AuthenticationStrategy[];

	@OneToMany(() => BudgetPeriod, (budgetPeriod) => budgetPeriod.organization, {
		nullable: true,
	})
	budgetPeriods?: BudgetPeriod[] | Partial<BudgetPeriod>[];

	@ManyToMany(() => Retailer, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationRetailers' })
	retailers?: Retailer[] | Partial<Retailer>[];

	@ManyToMany(() => Agency, {
		eager: true,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationAgencies' })
	agencies?: Agency[] | Partial<Agency>[];

	@OneToMany(() => Brand, (brand) => brand.organization, {
		nullable: true,
	})
	brands?: Brand[] | Partial<Brand>[];

	@OneToMany(() => Category, (category) => category.organization, {
		nullable: true,
	})
	categories?: Category[] | Partial<Category>[];

	@OneToMany(() => FundingSource, (fundingSource) => fundingSource.organization, {
		nullable: true,
	})
	fundingSources?: FundingSource[] | Partial<FundingSource>[];

	@OneToMany(() => BrandInitiative, (brandInitiative) => brandInitiative.organization, {
		nullable: true,
	})
	brandInitiatives?: BrandInitiative[] | Partial<BrandInitiative>[];

	@ManyToMany(() => VendorType, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationVendorTypes' })
	vendorTypes?: VendorType[] | Partial<VendorType>[];

	@OneToMany(() => Vendor, (vendor) => vendor.id, {
		nullable: true,
	})
	vendors?: Vendor[] | Partial<Vendor>[];

	@ManyToMany(() => ProgramPhase, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationProgramPhases' })
	programPhases?: ProgramPhase[] | Partial<ProgramPhase>[];

	@ManyToMany(() => ProgramSector, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationProgramSectors' })
	programSectors?: ProgramSector[] | Partial<ProgramSector>[];

	@ManyToMany(() => ProgramType, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationProgramTypes' })
	programTypes?: ProgramType[] | Partial<ProgramType>[];

	@OneToMany(() => ProgramUtilization, (programUtilization) => programUtilization.organization, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	programUtilizations?: ProgramUtilization[] | Partial<ProgramUtilization>[];

	@ManyToMany(() => TacticPhase, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationTacticPhases' })
	tacticPhases?: TacticPhase[] | Partial<TacticPhase>[];

	@ManyToMany(() => CostType, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationCostTypes' })
	costTypes?: CostType[] | Partial<CostType>[];

	@OneToMany(() => MeasurementGroup, (measurementGroup) => measurementGroup.organization, {
		nullable: true,
	})
	measurementGroups?: MeasurementGroup[] | Partial<MeasurementGroup>[];

	@ManyToMany(() => MeasurementType, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationMeasurementTypes' })
	measurementTypes?: MeasurementType[] | Partial<MeasurementType>[];

	@ManyToMany(() => ExternalIdType, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'organizationExternalIdTypes' })
	externalIdTypes?: ExternalIdType[] | Partial<ExternalIdType>[];

	@OneToMany(() => InvestmentType, (investmentType) => investmentType.organization, {
		nullable: true,
	})
	investmentTypes?: InvestmentType[] | Partial<InvestmentType>[];

	@Column('jsonb', { nullable: true })
	settings?: UpdateOrgSettingsDto;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	created: string;

	@OneToMany(() => TacticAgency, (tacticAgency) => tacticAgency.organization, {
		eager: false,
		nullable: true,
		cascade: true,
	})
	tacticAgencies?: PublicTacticAgency[] | Partial<PublicTacticAgency>[];

	public toPublic(include: Array<keyof Organization> = [], exclude: Array<keyof Organization> = []): PublicOrganization {
		const pub: Partial<PublicOrganization> = {
			id: this.id,
			name: this.name,
			slug: this.slug,
			logo: this.logo,
			remoteId: this.remoteId,
			signupMethod: this.signupMethod,
			settings: this.settings,
			created: this.created,
		};

		if (this.budgetPeriods?.length) {
			pub.budgetPeriods = (this.budgetPeriods as Partial<BudgetPeriod>[]).map((p) => {
				return new BudgetPeriod(p).toPublic();
			});
		}

		if (this.retailers?.length) {
			pub.retailers = (this.retailers as Partial<Retailer>[]).map((r) => {
				return new Retailer(r).toPublic();
			});
		}

		if (this.agencies?.length) {
			pub.agencies = (this.agencies as Partial<Agency>[]).map((r) => {
				return new Agency(r).toPublic();
			});
		}

		if (this.tacticPhases?.length) {
			pub.tacticPhases = (this.tacticPhases as Partial<TacticPhase>[]).map((p) => {
				return new TacticPhase(p).toPublic();
			});
		}

		if (this.programPhases?.length) {
			pub.programPhases = (this.programPhases as Partial<ProgramPhase>[]).map((p) => {
				return new ProgramPhase(p).toPublic();
			});
		}

		if (this.programSectors?.length) {
			pub.programSectors = (this.programSectors as Partial<ProgramSector>[]).map((s) => {
				return new ProgramSector(s).toPublic();
			});
		}

		if (this.programTypes?.length) {
			pub.programTypes = (this.programTypes as Partial<ProgramType>[]).map((t) => {
				return new ProgramType(t).toPublic();
			});
		}

		if (this.programUtilizations?.length) {
			pub.programUtilizations = (this.programUtilizations as Partial<ProgramUtilization>[]).map((t) => {
				return new ProgramUtilization(t).toPublic();
			});
		}

		if (this.costTypes?.length) {
			pub.costTypes = (this.costTypes as Partial<CostType>[]).map((t) => {
				return new CostType(t).toPublic();
			});
		}

		if (this.measurementGroups?.length) {
			pub.measurementGroups = (this.measurementGroups as MeasurementGroup[]).map((g) => new MeasurementGroup(g).toPublic());
		}

		if (this.measurementTypes?.length) {
			pub.measurementTypes = (this.measurementTypes as Partial<MeasurementType>[]).map((t) => {
				return new MeasurementType(t).toPublic();
			});
		}

		if (this.externalIdTypes?.length) {
			pub.externalIdTypes = (this.externalIdTypes as Partial<ExternalIdType>[]).map((t) => {
				return new ExternalIdType(t).toPublic();
			});
		}

		if (this.investmentTypes?.length) {
			pub.investmentTypes = (this.investmentTypes as Partial<InvestmentType>[]).map((t) => {
				return new InvestmentType(t).toPublic();
			});
		}

		if (this.tacticAgencies?.length) {
			pub.tacticAgencies = (this.tacticAgencies as PublicTacticAgency[]).map((t) => new TacticAgency(t).toPublic());
		}

		if (exclude.includes('retailers')) {
			delete pub.retailers;
		}

		if (exclude.includes('created')) {
			delete pub.created;
		}

		if (exclude.includes('costTypes')) {
			delete pub.costTypes;
		}

		if (exclude.includes('programPhases')) {
			delete pub.programPhases;
		}

		if (exclude.includes('programSectors')) {
			delete pub.programSectors;
		}

		if (exclude.includes('programTypes')) {
			delete pub.programTypes;
		}

		if (exclude.includes('programUtilizations')) {
			delete pub.programUtilizations;
		}

		if (exclude.includes('tacticPhases')) {
			delete pub.tacticPhases;
		}

		if (exclude.includes('budgetPeriods')) {
			delete pub.budgetPeriods;
		}

		if (exclude.includes('tacticAgencies') && pub?.tacticAgencies) {
			delete pub.tacticAgencies;
		}

		return pub as PublicOrganization;
	}
}
