import { Pipe, PipeTransform } from '@angular/core';
import { resolveDotNotationPath } from '../../_core/utils/object.utils';
import { Column, TableRow } from '../../state/table/table.model';
import { isObject } from '../../_core/utils/types.utils';
import { getValueTypeMaskedValue } from '../../state/table/table.utils';

@Pipe({
	name: 'getTableCellValue',
})
export class GetTableCellValuePipe implements PipeTransform {
	transform(column: Column, row: TableRow<any>, cellValue: string | any, expectValueProperty: boolean): any {
		let data = '';

		// Skip pagination row
		if (row.type === 'pagination') {
			return '';
		}

		const content = resolveDotNotationPath(column.path, row);

		if (expectValueProperty) {
			data = content?.value || content?.rawValue?.value || content?.rawValue?.name;
		} else {
			data = content;
		}

		// For Location, if the row is not of the correct type, return an empty string
		if (column.id.includes('country') && !column.entityTypes.includes(row.type?.toLowerCase())) {
			return '';
		}

		// For Funding Source, if the row is not of the correct type, return an empty string
		if (column.id.includes('funding-source') && !column.entityTypes.includes(row.type?.toLowerCase())) {
			return '';
		}

		// For Tags, if the row is not of the correct type, return an empty string
		if (column.id.includes('tags') && !column.entityTypes.includes(row.type?.toLowerCase())) {
			return '';
		}

		// Fallback if the value is an object
		if (column.id === 'id' && isObject(data)) {
			data = (data as any).value;
		}

		// Fallback if the value is a custom type
		if (column.type === 'custom' && !data) {
			data = getValueTypeMaskedValue(data, column.type, column, row, undefined, true) as string;
		}

		return data || cellValue || '';
	}
}
