import { Injectable, Injector } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { OsService } from '@wppopen/angular';
import { catchError, concatMap, EMPTY, map, Observable, of, take, tap, throwError } from 'rxjs';
import { GlobalService } from '../../../state/global/global.service';
import { WppOpenUtils } from '../../../state/integrations/wpp-open/wpp-open.utils';
import { SessionQuery } from '../../../state/session/session.query';
import { SessionService } from '../../../state/session/session.service';
import { appRoutes, emptyRoutes } from '../../../app-routing.module';
import { OsContextValue } from '@wppopen/angular/wpp-open.service';
import { Location } from '@angular/common';
import { WppOpenAppContextDto } from '../../../state/integrations/wpp-open/dtos/wpp-open-app-context.dto';
import { DataService } from '../../../_core/services/data.service';
import { WppOpenUserAuthDto } from '../../../state/integrations/wpp-open/dtos/WppOpenUserAuthDto';
import { ApiErrorSource, WppOpenMiddlewareService } from '../../../state/integrations/wpp-open/wpp-open-middleware.service';
import { ApiError } from '../../../../../../api/src/_core/interfaces/api-error.interface';

export enum WppOpenCustomContextKeys {
	ROUTE = 'route',
}

@Injectable({ providedIn: 'root' })
export class WppOpenMicroAppInitializationService {
	readonly LOG_TAG = 'WppOpenMicroApp';
	constructor(
		private readonly injector: Injector,
		private readonly router: Router,
		private readonly location: Location,
		private readonly sessionQuery: SessionQuery,
		private readonly sessionService: SessionService,
		private readonly globalService: GlobalService,
		private readonly dataService: DataService<Partial<WppOpenUserAuthDto> | Partial<ApiError>>,
		private readonly wppOpenMiddlewareService: WppOpenMiddlewareService
	) {}

	init(): Observable<any> {
		// If running under single-spa, initialize the Wpp Open micro app
		console.log(this.LOG_TAG, 'isRunningUnderSingleSpa', WppOpenUtils.isRunningUnderSingleSpa);
		if (WppOpenUtils.isRunningUnderSingleSpa) {
			return this._initializeWppOpenMicroApp();
		} else {
			// If not running under single-spa, initialize the app as usual
			return of(undefined);
		}
	}

	private _initializeWppOpenMicroApp(): Observable<any> {
		const osService = this.injector.get(OsService, null);
		console.log(this.LOG_TAG, 'Initializing Wpp Open Micro App', osService);
		if (!osService) {
			return throwError(() => new Error('No OS service found'));
		}
		let appContext: WppOpenAppContextDto;
		return osService.context$.pipe(
			take(1),
			// Chain the sign-in observable if needed and pass the context along
			concatMap((osContext) => {
				// Initialize view for WppOpen
				this.globalService.setViewMode('wpp-open');

				// Register base routes
				const baseUrl = osContext?.osContext?.baseUrl;
				if (!baseUrl) {
					return throwError(() => new Error('No base URL found'));
				}

				console.log(this.LOG_TAG, 'Wpp Open Micro OS context', osContext);

				// const isExclusive = environment.exclusive;
				// // If the app is in exclusive mode, check if the Wpp Open metadata matches our organization
				// if (!isExclusive && !this.checkAndSetMatchedOrganizationSettings(osContext)) {
				// 	return throwError(() => new Error('Failed to pair Wpp Open metadata with internal'));
				// }
				appContext = WppOpenUtils.extractMicroAppContext((osContext as OsContextValue)?.osContext);
				const wppOpenUserAuthDto = WppOpenUtils.extractUserAuth(osContext);

				// If the user is not logged in, perform wppOpenSignIn and wait for it
				if (!this.sessionQuery.isLoggedIn()) {
					return this.wppOpenMiddlewareService
						.authAndSetGlobalOrganizationConfig(wppOpenUserAuthDto.email, wppOpenUserAuthDto.token)
						.pipe(
							tap(() => this.globalService.setViewMode('wpp-open')),
							// After sign-in completes, emit the original context for the next step
							map(() => osContext),
							catchError((err: ApiErrorSource) => {
								const navigateOn404 = err.source === 'wppOpenSignIn';
								if (err.statusCode === 404 && navigateOn404) {
									// Navigate to request page
									this.dataService.setData({
										token: wppOpenUserAuthDto.token,
										email: wppOpenUserAuthDto.email,
									});
									this.location.go(`${osContext.osContext.baseUrl.replace(/\/$/, '')}/wpp-open/request`);
									this._registerRoutes(baseUrl);
									return EMPTY;
								} else {
									this.dataService.setData(err);
									this.location.go(`${osContext.osContext.baseUrl.replace(/\/$/, '')}/wpp-open/error`);
									this._registerRoutes(baseUrl);
									return EMPTY;
								}
							})
						);
				}
				// If already logged in, simply continue with the context
				return of(osContext);
			}),
			// Next, chain the baseUrl observable
			concatMap(() => osService.baseUrl$.pipe(take(1))),
			tap((baseUrl) => {
				console.log(this.LOG_TAG, 'Version data: ', appContext.appInstance.devhubMetadata.version);
				// Key for custom route
				const definedRoute = (appContext?.appInstance?.devhubMetadata?.version?.customContext as any[]).find((value) => {
					return value.key === WppOpenCustomContextKeys.ROUTE;
				})?.value;

				if (!definedRoute) {
					this.globalService.triggerErrorMessage(undefined, 'Missing defined custom context.');
					return throwError(() => new Error('Missing defined custom context.'));
				}

				// Navigate to the defined route
				this.location.go(`${baseUrl.replace(/\/$/, '')}/${definedRoute}`);
				this._registerRoutes(baseUrl);
			})
		);
	}

	/**
	 * Register routes
	 *
	 * @param baseUrl
	 * @private
	 */
	private _registerRoutes(baseUrl: string): void {
		const mappedRoutes = appRoutes.map((route) => ({
			...route,
			path: route.path ? `${baseUrl}/${route.path}` : baseUrl,
			redirectTo: route.redirectTo ? `${baseUrl}/${route.redirectTo}` : undefined,
		}));

		const routerConfig: Routes = [...mappedRoutes, ...emptyRoutes];
		console.log(this.LOG_TAG, 'Mapped Routes', routerConfig);
		this.router.resetConfig(routerConfig);
		if (!this.router.navigated) {
			this.router.initialNavigation();
		}
	}
}
