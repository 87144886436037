<div class="box-container">
	<!-- If there is no success message yet, show the request form -->

	<h2>Ooopsy Daisy something went wrong!</h2>

	<pre *ngIf="!environment.production">{{ data | json }}</pre>
	<p class="text-center mt-2">
		<ng-container *ngIf="loading$ | async">
			<p class="spinner full-width display-flex">
				<mat-spinner></mat-spinner>
			</p>
		</ng-container>
	</p>

	<p class="error-message">
		<ng-container *ngIf="(loading$ | async) === false">
			<ng-container *ngIf="data">
				{{ data?.message ?? "Unknown error" }}
			</ng-container>
		</ng-container>
	</p>
	<p>
		For additional help, contact support
		<a href="mailto:simplcx@vmlyrcommerce.com" [cdkCopyToClipboard]="'simplcx@vmlyrcommerce.com'"> here</a>
	</p>
</div>
