import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DataService<T = any> {
	private _data: T | null = null;

	setData(data: T): void {
		this._data = data;
	}

	getData(): T | null {
		return this._data;
	}

	clearData(): void {
		this._data = null;
	}
}
