import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PublicOrganization } from '../../../../../../api/src/organization/organization.entity';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WppOpenIntegrationService {
	public defaultHeaders = new HttpHeaders({
		Accept: 'application/json',
	});
	constructor(private httpClient: HttpClient) {}

	getOrganizationFromHub(email: string, accessToken: string): Observable<Partial<PublicOrganization>> {
		// Wu se first to retrieve organization mapping
		const apiUrl = environment?.apiSettings?.[0]?.endpoint;
		if (!apiUrl) {
			throw new Error('API url not defined in environment api settings');
		}

		const headers = this.defaultHeaders;
		return this.httpClient.post<Partial<PublicOrganization>>(
			`${apiUrl}/integrations/wpp-open/hub-to-organization`,
			{
				email,
				accessToken,
			},
			{ headers }
		);
	}
}
